module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\menno\\Documents\\Coding\\we-on-bikes","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"C:/Users/menno/Documents/Coding/we-on-bikes/node_modules/gatsby-remark-images","id":"955a84d3-ee54-5694-a6b1-2911e3131413","name":"gatsby-remark-images","version":"6.19.0","modulePath":"C:\\Users\\menno\\Documents\\Coding\\we-on-bikes\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[],"maxWidth":600,"showCaptions":false,"quality":75,"withWebp":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"C:/Users/menno/Documents/Coding/we-on-bikes/node_modules/gatsby-remark-autolink-headers","id":"fecdf44d-a7ee-537b-837b-7c3e69a4bf19","name":"gatsby-remark-autolink-headers","version":"5.19.0","modulePath":"C:\\Users\\menno\\Documents\\Coding\\we-on-bikes\\node_modules\\gatsby-remark-autolink-headers\\index.js","pluginOptions":{"plugins":[],"offsetY":"300","icon":false,"className":"ni","elements":["h2","h3"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"C:/Users/menno/Documents/Coding/we-on-bikes/node_modules/gatsby-remark-external-links","id":"ba0435ef-9b07-5c28-a01a-374dc78a0d64","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"C:\\Users\\menno\\Documents\\Coding\\we-on-bikes\\node_modules\\gatsby-remark-external-links\\index.js","pluginOptions":{"plugins":[],"target":"_blank","rel":"noopener noreferrer"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\menno\\Documents\\Coding\\we-on-bikes","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"showCaptions":false,"quality":75,"withWebp":true},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"300","icon":false,"className":"ni","elements":["h2","h3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"defaultLanguage":"de","siteUrl":"http://https://weonbikes.com/","redirect":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["nl","en","pt"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"We On Bikes","short_name":"We On Bikes","description":"Radwandern ist unsere Leidenschaft. Praktische Tipps, Radrouten, Packlisten - unsere Erfahrungen zu Bikepacking, Radtouren und Fahrradleben teilen wir mit euch in unserem Blog und unserem Buch.","lang":"de","display":"standalone","icon":"src/images/icon.png","icon_options":{"purpose":"any"},"start_url":"/","background_color":"#000000","theme_color":"#0d4880","localize":[{"start_url":"/en/","lang":"en","name":"We On Bikes","short_name":"We On Bikes","description":"Bicycle touring is our passion; practical tips, cycling routes, packing lists - we share our experiences on bikepacking, bike trips and cycling life with you in our blog and book."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c2f3c73ba1f7ac68f4c7492a26e04815"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
